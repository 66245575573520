<template>
  <app-toast
    :show="isOpen"
    :text="text"
    :secondary="secondary"
    class="app-toast-wrapper"
  >
    <component
      :is="icon"
      v-if="icon"
      width="17"
      height="17"
    />
  </app-toast>
</template>

<script setup>
  import { storeToRefs } from 'pinia'
  import { useToastStore } from '~/stores/toast'

  const toastStore = useToastStore()
  const {
    isOpen,
    text,
    icon,
    secondary,
  } = storeToRefs(toastStore)
</script>

<style lang="scss">
  .app-toast-wrapper {
    z-index: z('app-toast');
  }
</style>
