<template>
  <output
    class="app-toast"
    :class="{
      'app-toast--show': show,
      'app-toast--secondary': secondary,
    }"
  >
    <span class="app-toast__text">{{ text }}</span>

    <span
      class="app-toast__icon"
      aria-hidden="true"
    >
      <slot />
    </span>
  </output>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        default: '',
      },
      show: {
        type: Boolean,
        default: false,
      },
      secondary: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .app-toast {
    --toast-background-color: var(--color-primary, #3a3a3a);
    --toast-text-color: var(--color-lightest, #ffffff);
    --toast-font-weight: var(--font-weight-normal, 400);
    --toast-font-size: var(--font-size-small, 12px);

    // According to Material Design this should be the maximum- and minimum width on desktop when working with a toast
    // SOURCE: https://material.io/archive/guidelines/components/snackbars-toasts.html#snackbars-toasts-usage
    --maximum-toast-width: 568px;
    --minimum-toast-width: 288px;

    position: fixed;
    top: var(--toast-top-offset, 0);
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: var(--spacing-small) var(--spacing-default);
    background: var(--toast-background-color);
    color: var(--toast-text-color);
    transform: translateY(-100%);
    opacity: 0;
    transition: transform .2s ease-in-out, opacity .2s ease-in-out;
    pointer-events: none;

    @include breakpoint(md) {
      width: fit-content;
      min-width: var(--minimum-toast-width);
      max-width: var(--maximum-toast-width);
      top: var(--toast-top-offset, var(--spacing-default));
      right: var(--spacing-default);
      bottom: auto;
      left: auto;
    }
  }

  .app-toast--secondary {
    --toast-background-color: white;
    --toast-text-color: var(--color-primary);
  }

  .app-toast--show {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto;
  }

  .app-toast__text {
    flex-grow: 1;
    margin-right: var(--spacing-default);
    font-size: var(--toast-font-size);
    font-weight: var(--toast-font-weight);
    line-height: var(--line-height-default);
  }

  .app-toast__icon {
    flex-shrink: 0;
  }
</style>
